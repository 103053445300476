import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../components/login.vue'
import index from '../components/index.vue'

Vue.use(VueRouter)
let originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location){
  return originPush.call(this,location).catch(err => err)
  // console.log(location)
}
const routes = [
  {
    path: '/',
    component: login,
    meta:{'title':'登录'}
  },
  {
    path:'/index',
    component:index,
    meta:{'title':'大数据监测平台'}
  }
]

const router = new VueRouter({
  mode:'history',
  routes
})
router.afterEach((to,from)=>{
  // console.log(to,',,,,,',from)
  document.title = to.meta.title
})
export default router
