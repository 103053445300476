<template>
  <div
    class="contains"
    v-loading="tops"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="headers"></div>
    <div class="tops">
      <div>
        <div><img src="../assets/img/all.png" alt="" srcset="" /></div>
        <div>
          <div>订单总数</div>
          <div>
            <dv-digital-flop
              :config="config3"
              style="width: 100%; height: 5rem"
            />
          </div>
        </div>
      </div>
      <div>
        <div><img src="../assets/img/wx.png" alt="" srcset="" /></div>
        <div>
          <div>微信订单数</div>
          <div>
            <dv-digital-flop
              :config="config4"
              style="width: 100%; height: 5rem"
            />
          </div>
        </div>
      </div>
      <div>
        <div><img src="../assets/img/dy.png" alt="" srcset="" /></div>
        <div>
          <div>抖音订单数</div>
          <div>
            <dv-digital-flop
              :config="config5"
              style="width: 100%; height: 5rem"
            />
          </div>
        </div>
      </div>
      <div>
        <div><img src="../assets/img/ks.png" alt="" srcset="" /></div>
        <div>
          <div>快手订单数</div>
          <div>
            <dv-digital-flop
              :config="config6"
              style="width: 100%; height: 5rem"
            />
          </div>
        </div>
      </div>
      <div>
        <div><img src="../assets/img/monery.png" alt="" srcset="" /></div>
        <div>
          <div>总金额</div>
          <div>
            <dv-digital-flop
              :config="config7"
              style="width: 100%; height: 5rem"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mains">
      <div class="left">
        <div class="left_one">
          <div id="echarts0"></div>
          <div id="echarts1"></div>
          <div id="echarts2"></div>
          <div id="echarts3"></div>
          <div id="echarts4"></div>
          <div id="echarts5"></div>
        </div>
        <div class="left_tow">
          <div class="titles2">补贴活动</div>
          <div class="lefts_contyopsr">结束倒计时：{{ timestt }}</div>
          <div class="left_headers">
            <span>项目</span>
            <span>基础返现</span>
            <span>补贴返现</span>
            <span>补贴后返现</span>
          </div>
          <div class="left_contentys">
            <div class="lefts_contyops">
              <span>微信</span>
              <span>0.25元</span>
              <span>{{ wxprices }}元</span>
              <span>{{ 0.25 + wxprices * 1 }}元</span>
            </div>
            <div class="lefts_contyops">
              <span>抖音</span>
              <span>0.25元</span>
              <span>{{ dyprices }}元</span>
              <span>{{ 0.25 * 1 + dyprices * 1 }}元</span>
            </div>
            <div class="lefts_contyops">
              <span>抖音极速版</span>
              <span>0.25元</span>
              <span>{{ dyliteprices }}元</span>
              <span>{{ 0.25 + dyliteprices * 1 }}元</span>
            </div>
            <div class="lefts_contyops">
              <span>快手</span>
              <span>0.25元</span>
              <span>{{ wxprices }}元</span>
              <span>{{ 0.25 + wxprices * 1 }}元</span>
            </div>
            <div class="lefts_contyops">
              <span>快手极速版</span>
              <span>0.25元</span>
              <span>{{ wxprices }}元</span>
              <span>{{ 0.25 + wxprices * 1 }}元</span>
            </div>
          </div>
        </div>
      </div>
      <div class="center" id="maps"></div>
      <div class="right">
        <div>
          <div class="list">
            <div class="titles1">订单综合排名</div>
            <div class="list_header">
              <span>运营商</span>
              <span>微信</span>
              <span>抖音</span>
              <span>抖音极速版</span>
              <span>快手</span>
              <span>快手极速版</span>
              <span>总计</span>
            </div>
            <div class="contyuis">
              <transition-group name="flip-list" class="box">
                <div
                  class="list_contents"
                  v-for="item in tableData"
                  :key="item.name"
                  draggable="true"
                  :class="{ active: oldVal == item && isActive == item }"
                  @dragstart="dragstart(item)"
                  @dragenter="dragenter(item)"
                  @dragend="dragend(item)"
                >
                  <span>{{ item.name }}</span>
                  <span style="color: yellow">{{ item.weixinnum }}个</span>
                  <span style="color: #3be1c5">{{ item.douyinnum }}个</span>
                  <span style="color: #e03ed3">{{ item.dylitenum }}个</span>
                  <span style="color: #1affa7">{{item.ksnum}}个</span>
                  <span style="color: #ff7e1a">0个</span>
                  <span style="color: #29d3ff"
                    >{{
                      item.weixinnum + item.douyinnum + item.dylitenum+item.ksnum
                    }}个</span
                  >
                </div>
              </transition-group>
            </div>
          </div>
        </div>
        <div>
          <div class="list">
            <div class="titles1">返现综合排名</div>
            <div class="monery_header">
              <span>运营商</span>
              <span>微信</span>
              <span>抖音</span>
              <span>抖音极速版</span>
              <span>快手</span>
              <span>快手极速版</span>
              <span>补贴</span>
              <span>总计</span>
            </div>
            <div class="monery_contents">
              <transition-group name="flip-lists" class="box">
                <div
                  class="list_contents"
                  v-for="item in tableDataer"
                  :key="item.name"
                  draggable="true"
                  :class="{ active: oldVal2 == item && isActive2 == item }"
                  @dragstart="dragstart2(item)"
                  @dragenter="dragenter2(item)"
                  @dragend="dragend2(item)"
                >
                  <span>{{ item.name }}</span>
                  <span style="color: yellow"
                    >{{
                      (item.weixinnum * (25 + wxprices * 100)) / 100
                    }}元</span
                  >
                  <span style="color: #3be1c5"
                    >{{
                      (item.douyinnum * (25 + dyprices * 100)) / 100
                    }}元</span
                  >
                  <span style="color: #e03ed3"
                    >{{
                      (item.dylitenum * (25 + dyliteprices * 100)) / 100
                    }}元</span
                  >
                  <span style="color: #1affa7">{{(item.ksnum * (25 + dyprices * 100)) / 100}}元</span>
                  <span style="color: #ff7e1a">0元</span>
                  <span style="color: #27ff00"
                    >{{
                      (item.weixinnum * (wxprices * 100) +
                        item.douyinnum * (dyprices * 100) +
                        item.dylitenum * (dyliteprices * 100)) /
                      100
                    }}元</span
                  >
                  <span style="color: #29d3ff"
                    >{{
                      (item.weixinnum * (25 + wxprices * 100) +
                        item.douyinnum * (25 + dyprices * 100) +
                        item.dylitenum * (25 + dyliteprices * 100)+item.ksnum * (25 + dyprices * 100)) /
                      100
                    }}元</span
                  >
                </div>
              </transition-group>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="times">{{ date }}</div>
  </div>
</template>

<script>
import $ from "jquery";
var times = null;
var times2 = null;
var times3 = null;
export default {
  data() {
    return {
      screenHeight: "",
      echarts0: "",
      echarts1: "",
      echarts2: "",
      echarts3: "",
      echarts4: "",
      date: "",
      echarts5: "",
      trees: "",
      tableData: [],
      tableDataer: [],
      dytotals: 0,
      wxtotals: 0,
      dyliteTotals: 0,
      config3: {
        number: [0],
        content: "{nt}个",
        textAlign: "left",
        style: {
          color: "#00B5FF",
        },
      },
      config4: {
        number: [0],
        content: "{nt}个",
        textAlign: "left",
        style: {
          fill: "#9C9E15",
        },
      },
      config5: {
        number: [0],
        content: "{nt}个",
        textAlign: "left",
        style: {
          fill: "#E03ED3",
        },
      },
      config6: {
        number: [0],
        content: "{nt}个",
        textAlign: "left",
        style: {
          fill: "#1AFFA7",
        },
      },
      config7: {
        number: [0],
        content: "{nt}元",
        textAlign: "left",
        toFixed: 2,
        style: {
          fill: "#29D3FF",
        },
      },
      tops: true,
      isActive: 0,
      oldVal: 0,
      newOld: 0,
      isActive2: 0,
      oldVal2: 0,
      newOld2: 0,
      wxprices: 0,
      dyprices: 0,
      dyliteprices: 0,
      settimes: 20000,
      timestt: "00:00:00",
      dietime: "2022/5/25 00:00:00",
    };
  },
  beforeUnmount() {
    clearTimeout(times);
    clearTimeout(times);
    clearTimeout(times);
    clearTimeout(times);
    clearTimeout(times);
    clearTimeout(times2);
    clearTimeout(times2);
    clearTimeout(times2);
    clearTimeout(times2);
    clearTimeout(times2);
    clearTimeout(times2);
    clearTimeout(times3);
    clearTimeout(times3);
    clearTimeout(times3);
    clearTimeout(times3);
    clearTimeout(times3);
  },
  mounted() {
    console.log(this.x)
    // this.x.$on('hello',(data)=>{
    //     console.log('我是Index组件，收到新的数据',data)
    // })
    var account = window.sessionStorage.getItem("account");
    if (account == "" || account == null) {
      this.$router.push("/");
    }
    this.creatmaps();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientHeight;
        that.screenHeight = window.screenWidth;
      })();
    };
    this.getnewData();
    this.getNewtime();
    this.getdoums();
  },
  methods: {
    creatmaps() {
      const that = this;
      that.trees = that.$echarts.init(document.getElementById("maps"));
      var points = [
        { value: [118.8062, 31.9208], itemStyle: { color: "#4ab2e5" } },
        { value: [127.9688, 45.368], itemStyle: { color: "#4fb6d2" } },
        { value: [110.3467, 41.4899], itemStyle: { color: "#52b9c7" } },
        { value: [125.8154, 44.2584], itemStyle: { color: "#5abead" } },
        { value: [116.4551, 40.2539], itemStyle: { color: "#f34e2b" } },
        { value: [123.1238, 42.1216], itemStyle: { color: "#f56321" } },
        { value: [114.4995, 38.1006], itemStyle: { color: "#f56f1c" } },
        { value: [117.4219, 39.4189], itemStyle: { color: "#f58414" } },
        { value: [112.3352, 37.9413], itemStyle: { color: "#f58f0e" } },
        { value: [109.1162, 34.2004], itemStyle: { color: "#f5a305" } },
        { value: [103.5901, 36.3043], itemStyle: { color: "#e7ab0b" } },
        { value: [106.3586, 38.1775], itemStyle: { color: "#dfae10" } },
        { value: [101.4038, 36.8207], itemStyle: { color: "#d5b314" } },
        { value: [103.9526, 30.7617], itemStyle: { color: "#c1bb1f" } },
        { value: [108.384366, 30.439702], itemStyle: { color: "#b9be23" } },
        { value: [113.0823, 28.2568], itemStyle: { color: "#a6c62c" } },
        { value: [102.9199, 25.46639], itemStyle: { color: "#96cc34" } },
        { value: [119.4612, 35.4285] },
      ];
      let index = -1;
      var urls = "http://data.xiaoyuc.com/map.json";
      $.getJSON(urls, function (geoJson) {
        that.$echarts.registerMap("china", geoJson);
        var option = {
          geo: {
            map: "china",
            aspectScale: 0.85, //长宽比
            zoom: 1.1,
            roam: false,
            itemStyle: {
              normal: {
                areaColor: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#09132c", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#274d68", // 100% 处的颜色
                    },
                  ],
                  globalCoord: true, // 缺省为 false
                },
                shadowColor: "rgb(58,115,192)",
                shadowOffsetX: 10,
                shadowOffsetY: 11,
              },
              emphasis: {
                areaColor: "#2AB8FF",
                borderWidth: 0,
                color: "green",
                label: {
                  show: false,
                },
              },
            },
            regions: [
              {
                name: "南海诸岛",
                itemStyle: {
                  areaColor: "rgba(0, 10, 52, 1)",

                  borderColor: "rgba(0, 10, 52, 1)",
                  normal: {
                    opacity: 0,
                    label: {
                      show: false,
                      color: "#009cc9",
                    },
                  },
                },
              },
            ],
          },
          series: [
            {
              type: "map",
              roam: false,
              label: {
                normal: {
                  show: true,
                  textStyle: {
                    color: "#1DE9B6",
                  },
                },
                emphasis: {
                  textStyle: {
                    color: "rgb(183,185,14)",
                  },
                },
              },

              itemStyle: {
                normal: {
                  borderColor: "rgb(147, 235, 248)",
                  borderWidth: 1,
                  areaColor: {
                    type: "radial",
                    x: 0.5,
                    y: 0.5,
                    r: 0.8,
                    colorStops: [
                      {
                        offset: 0,
                        color: "#09132c", // 0% 处的颜色
                      },
                      {
                        offset: 1,
                        color: "#274d68", // 100% 处的颜色
                      },
                    ],
                    globalCoord: true, // 缺省为 false
                  },
                },
                emphasis: {
                  areaColor: "rgb(46,229,206)",
                  borderWidth: 0.1,
                },
              },
              zoom: 1.2,
              map: "china", //使用
            },
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              showEffectOn: "render",
              zlevel: 1,
              rippleEffect: {
                period: 15,
                scale: 4,
                brushType: "fill",
              },
              hoverAnimation: true,
              label: {
                normal: {
                  formatter: "{b}",
                  position: "right",
                  offset: [15, 0],
                  color: "#1DE9B6",
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: "#1DE9B6",
                  shadowBlur: 10,
                  shadowColor: "#333",
                },
              },
              symbolSize: 4,
              data: points,
            }, //地图线的动画效果
            {
              type: "lines",
              zlevel: 2,
              effect: {
                show: true,
                period: 4, //箭头指向速度，值越小速度越快
                trailLength: 0.4, //特效尾迹长度[0,1]值越大，尾迹越长重
                symbol: "arrow", //箭头图标
                symbolSize: 4, //图标大小
              },
              lineStyle: {
                normal: {
                  color: "#1DE9B6",
                  width: 1, //线条宽度
                  opacity: 0.1, //尾迹线条透明度
                  curveness: 0.3, //尾迹线条曲直度
                },
              },
              data: [
                {
                  coords: [
                    [118.8062, 31.9208],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#4ab2e5" },
                },
                {
                  coords: [
                    [127.9688, 45.368],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#4fb6d2" },
                },
                {
                  coords: [
                    [110.3467, 41.4899],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#52b9c7" },
                },
                {
                  coords: [
                    [125.8154, 44.2584],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#5abead" },
                },
                {
                  coords: [
                    [116.4551, 40.2539],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f34e2b" },
                },
                {
                  coords: [
                    [123.1238, 42.1216],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f56321" },
                },
                {
                  coords: [
                    [114.4995, 38.1006],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f56f1c" },
                },
                {
                  coords: [
                    [117.4219, 39.4189],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f58414" },
                },
                {
                  coords: [
                    [112.3352, 37.9413],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f58f0e" },
                },
                {
                  coords: [
                    [109.1162, 34.2004],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#f5a305" },
                },
                {
                  coords: [
                    [103.5901, 36.3043],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#e7ab0b" },
                },
                {
                  coords: [
                    [106.3586, 38.1775],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#dfae10" },
                },
                {
                  coords: [
                    [101.4038, 36.8207],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#d5b314" },
                },
                {
                  coords: [
                    [103.9526, 30.7617],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#c1bb1f" },
                },
                {
                  coords: [
                    [108.384366, 30.439702],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#b9be23" },
                },
                {
                  coords: [
                    [113.0823, 28.2568],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#a6c62c" },
                },
                {
                  coords: [
                    [102.9199, 25.46639],
                    [119.4612, 35.4285],
                  ],
                  lineStyle: { color: "#96cc34" },
                },
              ],
            },
          ],
        };
        that.trees.setOption(option, true);
      });
    },
    creattips(ids, names, values, values2,size1,size2) {
      var num = 0;
      this.ids = this.$echarts.init(document.getElementById(ids));
      var option = {
        title: [
          {
            text: names,
            x: "center",
            top: "37%",
            textStyle: {
              color: "#fdf914",
              fontSize: size1,
              fontWeight: "100",
            },
          },
          {
            text: values,
            x: "center",
            top: "52%",
            textStyle: {
              fontSize: size2,
              color: "#fdf914",
              fontFamily: "Lato",
              foontWeight: "600",
            },
          },
        ],
        polar: {
          radius: ["56%", "64%"],
          center: ["50%", "50%"],
        },
        angleAxis: {
          max: values2,
          show: false,
        },
        radiusAxis: {
          type: "category",
          show: true,
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            roundCap: true,
            barWidth: 60,
            showBackground: true,
            backgroundStyle: {
              color: "rgba(66, 66, 66, .3)",
            },
            data: [values],
            coordinateSystem: "polar",

            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#fdf914",
                  },
                  {
                    offset: 1,
                    color: "#38a700",
                  },
                ]),
              },
            },
          },
          {
            name: "",
            type: "pie",
            startAngle: 90,
            radius: ["73%", "75%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false,
                },
                color: "rgba(66, 66, 66, .4)",
                shadowBlur: 10,
                shadowColor: "rgba(253, 249, 20, .3)",
              },
            },
            data: [
              {
                value: values2,
              },
            ],
          },
          {
            name: "",
            type: "pie",
            startAngle: 90,
            radius: ["75.5%", "77%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false,
                },
                color: "rgba(66, 66, 66, .3)",
                shadowBlur: 10,
                shadowColor: "rgba(253, 249, 20, .3)",
              },
            },
            data: [
              {
                value: values2,
              },
            ],
          },
          {
            name: "",
            type: "pie",
            startAngle: 90,
            radius: ["77.5%", "78.8%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false,
                },
                color: "rgba(66, 66, 66, .2)",
                shadowBlur: 10,
                shadowColor: "rgba(253, 249, 20, .3)",
              },
            },
            data: [
              {
                value: values2,
              },
            ],
          },
          {
            name: "",
            type: "pie",
            startAngle: 90,
            radius: ["79.5%", "80.5%"],
            hoverAnimation: false,
            center: ["50%", "50%"],
            itemStyle: {
              normal: {
                labelLine: {
                  show: false,
                },
                color: "rgba(66, 66, 66, .1)",
                shadowBlur: 10,
                shadowColor: "rgba(253, 249, 20, .3)",
              },
            },
            data: [
              {
                value: values2,
              },
            ],
          },
        ],
      };
      this.ids.setOption(option);
    },
    // 获取头部实时数据
    getnewData() {
      clearTimeout(times);
      clearTimeout(times);
      clearTimeout(times);
      clearTimeout(times);
      clearTimeout(times);
      const that = this;
      var totals1 = 0;
      var totals2 = 0;
      var totals3 = 0;
      var totals4 = 0;
      var totals5 = 0;
      that.$http
        .post(
          "https://wx.xiaoyuc.com/home/datas/getonlineUserOrders",
          that.$qs.stringify({
            appids: "wx2ff34b38aa551a5c",
          })
        )
        .then(({ data }) => {
          that.wxtotals = data.wxtotals;
          that.dytotals = data.dytotals + data.dy_litetotals;
          that.tableData = [];
          that.tableDataer = [];
          that.tableData = that.tableData.concat(data.data);
          that.tableDataer = that.tableDataer.concat(data.data);
          that.wxprices = data.wxprices.toFixed(2);
          that.dyprices = data.dyprices.toFixed(2);
          that.dyliteprices = data.dy_lite_prices.toFixed(2);
          that.dietime = data.dietime;
          var width = document.body.clientWidth;
          if(width < 1100){
            var fontys = 18
            var size1 = 8;
            var size2 = 10
          }else{
            var fontys = 30
            var size1 = 12;
            var size2 = 14
          }
          for (var i = 0; i < that.tableData.length - 1; i++) {
            //每一轮比较要比多少次
            for (var j = 0; j < that.tableData.length - 1 - i; j++) {
              //如果第一个比第二个大，就交换他们两个位置
              if (that.tableData[j].totals+that.tableData[j].ksnum < that.tableData[j + 1].totals+that.tableData[j+1].ksnum) {
                var temp = that.tableData[j];
                that.tableData[j] = that.tableData[j + 1];
                that.tableData[j + 1] = temp;
              }
            }
          }
          for (var i = 0; i < that.tableDataer.length - 1; i++) {
            //每一轮比较要比多少次
            for (var j = 0; j < that.tableDataer.length - 1 - i; j++) {
              //如果第一个比第二个大，就交换他们两个位置
              if (
                (that.tableDataer[j].weixinnum * 25 +
                  that.tableDataer[j].douyinnum * 25 +
                  that.tableDataer[j].dylitenum * 25 +
                  that.tableDataer[j].ksnum * 25) /
                  100 <
                (that.tableDataer[j + 1].weixinnum * 25 +
                  that.tableDataer[j + 1].douyinnum * 25 +
                  that.tableDataer[j + 1].dylitenum * 25+
                  that.tableDataer[j + 1].ksnum * 25) /
                  100
              ) {
                var temp = that.tableDataer[j];
                that.tableDataer[j] = that.tableDataer[j + 1];
                that.tableDataer[j + 1] = temp;
              }
            }
          }
          that.config3 = {
            number: [data.wxtotals + that.dytotals+data.ksnum],
            content: "{nt}个",
            textAlign: "left",
            style:{
              fontSize:fontys
            }
          };
          that.config4 = {
            number: [data.wxtotals],
            content: "{nt}个",
            textAlign: "left",
            style: {
              fill: "#9C9E15",
              fontSize:fontys
            },
          };
          that.config5 = {
            number: [that.dytotals],
            content: "{nt}个",
            textAlign: "left",
            style: {
              fill: "#E03ED3",
              fontSize:fontys
            },
          };
          that.config6 = {
            number: [data.ksnum],
            content: "{nt}个",
            textAlign: "left",
            style: {
              fill: "#1AFFA7",
              fontSize:fontys
            },
          };
          var monerys =
            (data.wxtotals * (25 + data.wxprices * 100) +
              data.dytotals * (25 + data.dyprices * 100) +
              data.dy_litetotals * (25 + data.dy_lite_prices * 100)+data.ksnum * (25 + data.dy_lite_prices * 100)) /
            100;
          that.config7 = {
            number: [monerys],
            content: "{nt}元",
            toFixed: 2,
            textAlign: "left",
            style: {
              fill: "#29D3FF",
              fontSize:fontys
            },
          };
          totals4 =
            data.wxtotals + data.dytotals + data.dy_litetotals > 1000
              ? 5000
              : 1000;
          totals1 = data.wxtotals > 1000 ? 5000 : 1000;
          totals2 = data.dytotals > 500 ? 1000 : 500;
          totals3 = data.dy_litetotals > 500 ? 1000 : 500;
          that.creattips(
            "echarts0",
            "总订单",
            data.wxtotals + data.dytotals + data.dy_litetotals,
            totals4,
            size1,
            size2
          );
          that.creattips("echarts1", "微信", data.wxtotals, totals1,size1,size2);
          that.creattips("echarts2", "抖音", data.dytotals, totals2,size1,size2);
          that.creattips("echarts3", "抖音极速版", data.dy_litetotals, totals3,size1,size2);
          that.creattips("echarts4", "快手", data.ksnum, totals3,size1,size2);
          that.creattips("echarts5", "快手极速版", "0", "500",size1,size2);
          that.tops = false;
          times = setTimeout(() => {
            that.getnewData();
          }, that.settimes);
        });
    },
    // 实时时间
    getNewtime() {
      const that = this;
      clearInterval(times2);
      clearInterval(times2);
      clearInterval(times2);
      clearInterval(times2);
      clearInterval(times2);
      clearInterval(times2);
      var date = new Date();
      var year = date.getFullYear(); //获取年
      var month = date.getMonth() * 1 + 1; //获取年
      var hour = date.getHours(); //得到小时数
      if (hour < 10) {
        hour = "0" + hour;
      }
      var minute = date.getMinutes(); //得到分钟数
      if (minute < 10) {
        minute = "0" + minute;
      }
      var second = date.getSeconds(); //得到秒数
      if (second < 10) {
        second = "0" + second;
      }
      if (month < 10) {
        month = "0" + month;
      }
      var day = date.getDate(); //获取年
      if (day < 10) {
        day = "0" + day;
      }
      if (hour > 18) {
        this.settimes = 20000;
      } else if (hour > 15) {
        this.settimes = 10000;
      } else {
        this.settimes = 20000;
      }
      this.date =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      times2 = setTimeout(that.getNewtime, 1000);
    },
    dragstart(val) {
      // 保存旧的节点和当前正在操作的节点
      this.oldVal = val;
      this.isActive = val;
      console.log("-------dragstart val", val);
      // console.log("-------dragstart this.oldVal", this.oldVal);
      // console.log("-------dragstart this.isActive", this.isActive);
    },
    dragend(val) {
      if (this.oldVal != this.newOld) {
        let oldIndex = this.tableData.indexOf(this.oldVal);
        let newIndex = this.tableData.indexOf(this.newOld);
        let newItems = [...this.tableData];
        // 删除老的节点
        newItems.splice(oldIndex, 1);
        // 增加新的节点
        newItems.splice(newIndex, 0, this.oldVal);
        // items结构发生变化触发transition-group的动画
        this.tableData = [...newItems];
        // 重置正在操作的节点
        this.isActive = -1;
      }
    },
    // 记录移动过程中信息
    dragenter(val) {
      // 即保存要将当前移动的节点放到那个位置
      this.newOld = val;
      console.log("-----------dragenter val", val);
      console.log("-----------dragenter this.newOld", this.newOld);
    },
    dragstart2(val) {
      // 保存旧的节点和当前正在操作的节点
      this.oldVal2 = val;
      this.isActive2 = val;
    },
    dragend2(val) {
      if (this.oldVal2 != this.newOld2) {
        let oldIndex = this.tableDataer.indexOf(this.oldVal2);
        let newIndex = this.tableDataer.indexOf(this.newOld2);
        let newItems = [...this.tableDataer];
        // 删除老的节点
        newItems.splice(oldIndex, 1);
        // 增加新的节点
        newItems.splice(newIndex, 0, this.oldVal2);
        // items结构发生变化触发transition-group的动画
        this.tableDataer = [...newItems];
        // 重置正在操作的节点
        this.isActive2 = -1;
      }
    },
    // 记录移动过程中信息
    dragenter2(val) {
      // 即保存要将当前移动的节点放到那个位置
      this.newOld2 = val;
    },
    getdoums() {
      //获取当前时间
      const that = this;
      var date = new Date();
      var now = date.getTime();
      //设置截止时间
      var str = this.dietime;
      var endDate = new Date(str);
      var end = endDate.getTime();

      //时间差
      var leftTime = end - now;
      //定义变量 d,h,m,s保存倒计时的时间
      var d, h, m, s;
      if (leftTime >= 0) {
        d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        m = Math.floor((leftTime / 1000 / 60) % 60);
        s = Math.floor((leftTime / 1000) % 60);
        this.timestt = d + "天 " + h + "时" + m + "分" + s + "秒";
      } else {
        this.timestt = "已结束";
      }
      //递归每秒调用countTime方法，显示动态时间效果
      times3 = setTimeout(that.getdoums, 1000);
    },
  },
  watch: {
    screenHeight(val) {
      this.trees.resize();
    },
  },
};
</script>
<style scoped>
.contains {
  background: url(../assets/img/index.jpg) no-repeat;
  background-size: 100% 100%;
}
.headers {
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  background-size: center;
  padding-bottom: 1rem;
  position: relative;
  color: #fff;
  font-weight: 550;
}
.tops {
  width: 100%;
  height: 15rem;
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.mains {
  width: 100%;
  height: calc(100% - 26rem);
  display: flex;
}
.left {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.left_one {
  width: 100%;
  height: 60%;
  display: flex;
  flex-wrap: wrap;
}
.left_one > div {
  width: 50%;
  height: 13rem;
}
.center {
  width: 47%;
  height: 100%;
  /* background-color: aquamarine; */
}
.right {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tops > div {
  width: 16%;
  height: 80%;
  background: url(../assets/img/borders.png) no-repeat;
  background-size: contain;
  margin-right: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.tops > div:last-child {
  margin: 0% !important;
}
.tops > div > div:nth-of-type(1) {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5%;
}
.tops > div > div:nth-of-type(2) {
  width: 50%;
  height: 70%;
  display: flex;
  flex-direction: column;
  margin-left: 1%;
}
.tops > div > div:nth-of-type(1) > img {
  width: 6rem;
  height: 6rem;
}
.tops > div > div:nth-of-type(2) > div:nth-of-type(1) {
  display: flex;
  align-items: flex-end;
  font-size: 1.8rem;
  width: 100%;
  height: 35%;
}
.tops > div > div:nth-of-type(2) > div:nth-of-type(2) {
  display: flex;
  align-items: center;
  font-size: 2.6rem;
  flex: 1;
}
.right > div {
  width: 90%;
  height: 47.5%;
  background: url(../assets/img/xkk2.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.right > div:nth-of-type(2) {
  margin-top: 3%;
}
.list {
  width: 98%;
  height: calc(100% - 1rem);
  margin: auto;
  margin-top: 0.5rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
}
.contyuis {
  flex: 1;
  overflow: auto;
}
.contyuis::-webkit-scrollbar {
  display: none !important;
}
.list_header,
.list_contents {
  width: 100%;
  height: 4rem;
  display: flex;
  cursor: move;
}
.list_header > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 550;
  background-color: rgba(1, 139, 232, 0.4);
}
.list_contents > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 550;
}
.list_contents:nth-of-type(even) {
  background-color: rgba(3, 145, 180, 0.3);
}
.monery_header {
  width: 100%;
  height: 4rem;
  display: flex;
}
.monery_contents {
  flex: 1;
  overflow: auto;
}
.monery_contents::-webkit-scrollbar {
  display: none !important;
}
.monery_header > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 550;
  background-color: rgba(1, 139, 232, 0.4);
}
.monery_contents > div {
  width: 100%;
  height: 4rem;
  display: flex;
}
.monery_contents > div > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 550;
}
.times {
  position: absolute;
  top: 5%;
  left: 5%;
  color: #30ffe2;
}
.list_contents:hover {
  background-color: rgba(2, 250, 126, 0.4);
}
.box {
  width: 100%;
  height: 100%;
}
.left_tow {
  flex: 1;
  margin: 2rem 2rem;
  background: url(../assets/img/xkk2.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
.titles1 {
  width: 12rem;
  height: 4rem;
  background: url(../assets/img/xkk.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 3.5rem;
  left: -8.5rem;
  transform: rotate(-90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #fff;
}
.titles2 {
  width: 10rem;
  height: 3rem;
  background: url(../assets/img/xkk.png) no-repeat;
  background-size: 100% 100%;
  position: absolute;
  top: 3.5rem;
  right: -6.5rem;
  transform: rotate(90deg);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: #fff;
}
.left_contents {
  width: 98%;
  height: 95%;
  margin: auto;
  margin-top: 2%;
  overflow: auto;
}
.left_contents::-webkit-scrollbar {
  display: none;
}
.left_headers {
  width: 98%;
  height: 4rem;
  margin: auto;
  display: flex;
  background-color: rgba(1, 139, 232, 0.4);
}
.left_headers > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
}
.left_contentys {
  width: 98%;
  height: calc(100% - 9.5rem);
  margin: auto;
  overflow: auto;
}
.left_contentys::-webkit-scrollbar {
  display: none !important;
}
.lefts_contyops {
  width: 100%;
  height: 3.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.lefts_contyopsr {
  width: 100%;
  height: 3.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1affa7;
  margin-top: 1rem;
  font-size: 1.4rem;
}
.lefts_contyops > span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  color: #fff;
}
.lefts_contyops:nth-of-type(even) {
  background-color: rgba(3, 145, 180, 0.3);
}
.lefts_contyops > span:nth-of-type(2) {
  color: #29d3ff;
}
.lefts_contyops > span:nth-of-type(3) {
  color: #27ff00;
}
.lefts_contyops > span:nth-of-type(4) {
  color: #fff530;
}
</style>