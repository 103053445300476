import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/index.css'
import axios from 'axios'
import * as echarts from 'echarts'
import qs from 'qs'
import dataV from '@jiaminghi/data-view'
import './plugins/element.js'

Vue.use(dataV)
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.prototype.$qs = qs
Vue.prototype.$http = axios
console.log(Vue.prototype)
const demo = Vue.extend()
const d = new demo();
Vue.prototype.x = d

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
